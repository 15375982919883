/* CSS COMPONENT LOADING */
/* .loading{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    font-size: 2rem;
    color: white;
} */

svg {
    position: absolute;
}

circle:nth-of-type(1) {
    fill: #ffffff;
}
circle:nth-of-type(2) {
    fill: #fcd837;
}
circle:nth-of-type(3) {
    fill: #f9a11f;
}
circle:nth-of-type(4) {
    fill: #f27c21;
}

#clackers {
    /*   border: 1px solid white; */
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


